import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import heroHomepage1 from '../images/hero-homepage-1-patient.jpeg';
import heroHomepage2 from '../images/hero-homepage-2-lab.jpeg';
import heroHomepage3 from '../images/hero-homepage-3-data.jpeg';
import heroHomepage4 from '../images/hero-homepage-4.jpeg';

const TITLE_VALUES = [
  { header: 'With The Patient', img: heroHomepage1 },
  { header: 'In The Lab', img: heroHomepage2 },
  { header: 'With Actionable Data', img: heroHomepage3 },
  { header: 'With hc1 + Accumen', img: heroHomepage4 },
];

const CustomHeroBlock = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);

      setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % TITLE_VALUES.length);
        setFade(true); // Trigger fade-in
      }, 750);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [TITLE_VALUES.length]);

  return (
    <Container className="custom-hero vw-100 pb-md-6 pb-lg-0">
      <Row className="h-100">
        <Col xs={12} lg={6}>
          <div className="d-flex flex-column justify-content-center h-100 text-lg-start text-center">
            <h1 className="fw-700">Healthcare Progress Starts Here™</h1>
            <h1
              className={`text-orange rotating-header ${
                fade ? 'fade-in' : 'fade-out'
              }`}
            >
              {TITLE_VALUES[currentIndex].header}
            </h1>
            <p>
              We establish the lab as a strategic asset for health systems by
              combining advanced information technology with world-renowned
              expertise.
            </p>
            <div>
              <Button
                size="lg"
                variant="color_3"
                className="mt-4 rounded-pill px-5 py-3 fs-4 text-white fw-bold"
                href="https://hubs.ly/Q02TZqlN0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Button>
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="graphic-container">
              <div className="circular-image-container">
                <Image
                  src={TITLE_VALUES[currentIndex].img}
                  alt="Circular hero image"
                  layout="fill"
                  objectFit="cover"
                  className={`transition-opacity duration-750 ease-in-out ${
                    fade ? 'opacity-100' : 'opacity-0'
                  }`}
                  style={{ transition: 'opacity 0.75s ease-in-out' }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomHeroBlock;
