import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import graphic from '../images/hc1-accumenn-cta-graphic.png';

const CtaI4 = ({
  heading = null,
  solidBackgroundColor = 'white',
  content = null,
  link = null,
  linkText = null,
}) => {
  return (
    <div className={`cta-i3 bgg1 tcw before-${solidBackgroundColor}`}>
      <Container>
        <div className="ph1">
          <div className="d-flex justify-content-center bs1 cta-gradient-bg p-5">
            <Row>
              <Col xs={12} lg={5}>
                <div className="d-flex h-100 align-items-center justify-content-center p-5">
                  <Image src={graphic} alt="Accumen CTA Graphic" />
                </div>
              </Col>
              <Col xs={0} lg={1} />
              <Col>
                <div className="p-5 text-center text-lg-start">
                  <h2 className="text-white custom-cta-header">
                    Your lab can become
                  </h2>
                  <h2 className="text-white custom-cta-header">
                    a <i>transformative</i>
                  </h2>
                  <h2 className="text-white custom-cta-header">
                    strategic asset
                  </h2>
                  <div>
                    <Button
                      size="lg"
                      variant="outline-color_3"
                      className="mt-4 rounded-pill px-5 py-3 fs-4 fw-bold text-white"
                      href="https://hubs.la/Q02T_9vq0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Let Us Show You How
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CtaI4;
