import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Modules from '../components/modules';
import CustomHeroBlock from '../components/customHeroBlock';
import CtaI4 from '../components/ctaI4';

const Homepage2Template = ({ pageContext }) => {
  return (
    <Layout classNames="home page-template-default page" inverseHeader={false}>
      <Seo
        title="Home"
        seoTitle={pageContext.seo.seoTitle}
        seoDescription={pageContext.seo.seoDescription}
      />
      <section className="content-wrap">
        <CustomHeroBlock />
        <Modules modules={pageContext.modules} />
        <CtaI4 />
      </section>
    </Layout>
  );
};

export default Homepage2Template;
